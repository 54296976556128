import { useEffect, useState, useRef, useCallback, useContext, useMemo } from 'react'
import ReactPlayer from 'react-player'

import EasyDirectoryLogo from '../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'
import LicenseImg from '../../assets/images/Step1_BuyLicense.jpg'
import SupportImg from '../../assets/images/Step4_Support.jpg'

import Select from 'react-select'

import { v4 as uuid } from 'uuid'

import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CButton,
  CCardTitle,
  CCardText,
  CCardImage,
  CFormSwitch,
  CFormInput,
  CSpinner,
  CImage,
  CLink,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import {
  cilPhone,
  cilLoopCircular,
  cilX,
  cilTrash,
  cilGroup,
} from '@coreui/icons'

import "./EasyDirectory.css"
import { arraysAreEqual } from '@microsoft/mgt-element'
import { useTranslation } from 'react-i18next'
import { Seat, EasyDirectoryConfiguration, EasyTag, EasyDirectoryFeatures, ViewSelect, View, SharedMailbox } from '../../common/interfaces'
import RenderRoleManager from '../../components/EasyDirectory/RoleManager'
import ConfirmationModal from '../../components/modal/ConfirmationModal'
import { ConfigurationService } from '../../services/ConfigurationService'
import { GraphService } from '../../services/GraphService'
import { SubscriptionService } from '../../services/SubscriptionService'
import { EasyContext } from '../../components/context/EasyContext'
import { useIsAuthenticated } from '@azure/msal-react'

const EasyDirectory = () => {
  const { t } = useTranslation();
  const funMessage = "Looking for snow...";

  const easyContext = useContext(EasyContext);
  const isAuthenticated = useIsAuthenticated();

  const subscriptionService = new SubscriptionService(easyContext.accessToken);
  const configurationService = new ConfigurationService(easyContext.accessToken);
  const graphService = new GraphService(easyContext.accessToken, false);

  const [seats, setSeats] = useState<Seat[]>([]);

  const [configuration, setConfiguration] = useState<EasyDirectoryConfiguration>({});
  const [pristineOrgTags, setPristineOrgTags] = useState<EasyTag[]>([]);
  const [pristineOrgViews, setPristineOrgViews] = useState<EasyTag[]>([]);
  const [pristineSettings, setPristineSettings] = useState<EasyDirectoryFeatures>({});

  const [enableSaveSettings, setEnableSaveSettings] = useState(false);
  const [enableSaveTags, setEnableSaveTags] = useState(false);
  const [enableSaveViews, setEnableSaveViews] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [customViews, setOrganisationViews] = useState<ViewSelect[]>();
  const [viewSelectionList, setViewSelectionList] = useState([]);
  const [selectedView, setSelectedView] = useState<View>(null);
  const [easyDirectorySource, setEasyDirectorySource] = useState([{ address: "", id: "", label: "" }]);
  const [isPageConfigLoading, setIsPageConfigLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(t("SettingsSaved"));
  const [loadingMsg, setLoadingMsg] = useState(funMessage);

  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [onConfirm, setOnConfirm] = useState(() => () => { return }); // Default no-op function

  const [toast, addToast] = useState<any>();
  const toaster = useRef();

  const saveSettings = () => {
    const updateConfig = async () => {
      const settings: EasyDirectoryFeatures = {
        enablePersonalMailboxSearch: configuration.enablePersonalMailboxSearch,
        enableEntraIdSearch: configuration.enableEntraIdSearch,
        enableSharedMailboxSearch: configuration.enableSharedMailboxSearch,
        id: configuration?.id,
        sharedMailboxes: configuration?.sharedMailboxes
      };
      await configurationService.UpdateEDFeatures(settings)
        .then(() => {
          setPristineSettings({ ...settings } as EasyDirectoryFeatures);
          setSuccessMsg(t("SettingsSaved"));
          addToast(successfulToast);
        }).catch((e) => {
          setErrorMsg(e.message);
          addToast(errorToast);
        });
    };
    updateConfig();
  };

  const saveTags = () => {
    const updateConfig = async () => {
      await configurationService.UpdateOrgTags(configuration?.id, configuration?.orgTags)
        .then(() => {
          setPristineOrgTags([...configuration.orgTags]);
          setSuccessMsg(t("SettingsSaved"));
          addToast(successfulToast);
        }).catch((e) => {
          setErrorMsg(e.message);
          addToast(errorToast);
        });
    };
    updateConfig();
  };

  const saveView = () => {
    const updateConfig = async () => {
      await configurationService.UpdateOrgViews(configuration?.id, configuration?.orgViews)
        .then(() => {
          setPristineOrgViews([...configuration.orgViews]);
          setSuccessMsg(t("SettingsSaved"));
          addToast(successfulToast);
        }).catch((e) => {
          setErrorMsg(e.message);
          addToast(errorToast);
        });
    };
    updateConfig();
  };

  const openModal = (action: () => void) => {
    setOnConfirm(() => action);
    toggleModal();
  };

  const getConfiguration = useCallback(() => {
    setIsPageConfigLoading(true);
    setLoadingMsg(t("Loading"));
    configurationService.GetEasyDirectoryConfiguration().then((config) => {
      setConfiguration(config);
      const settings: EasyDirectoryFeatures = {
        enablePersonalMailboxSearch: config?.enablePersonalMailboxSearch,
        enableEntraIdSearch: config?.enableEntraIdSearch,
        enableSharedMailboxSearch: config?.enableSharedMailboxSearch,
        id: config?.id,
        sharedMailboxes: config?.sharedMailboxes
      };
      setPristineSettings(settings);
      setPristineOrgTags([...config.orgTags]);
      setPristineOrgViews([...config.orgViews]);
      setEasyDirectorySource(config.sharedMailboxes);
      setIsPageConfigLoading(false);
      getOrganisationViews(config);
    }).catch((e) => {
      setErrorMsg(e.message);
      addToast(errorToast);
      setIsPageConfigLoading(false);
    });
  }, []);


  const getOrganisationViews = useCallback((config: EasyDirectoryConfiguration) => {
    const convertToOptions = (views: View[]) => {
      const convertedViews = [];
      views.forEach(view => {
        convertedViews.push({
          value: view?.id,
          label: view?.label,
          mode: "View"
        });
      });
      return convertedViews;
    };

    configurationService.GetOrganisationViews().then(async (result) => {
      const views = [];
      const userOptions = [];

      for (const view of result) {
        const notAddedViews = view.view.filter(v => !config?.orgViews?.some(orgView => orgView.id === v.id));

        if (notAddedViews.length > 0) {
          const person = await graphService.getUser(view.userId);
          const username = person.displayName;

          views.push({
            label: username,
            options: convertToOptions(notAddedViews)
          });

          userOptions.push({
            value: view.userId,
            label: username
          });
        }
      }

      setViewSelectionList(views);
      setOrganisationViews(result);
    }).catch((e) => {
      console.log(e.message);
    });
  }, [graphService]);

  const getSeats = async (subId) => await subscriptionService.getEasyDirectorySeats(subId).then((seats: Seat[]) => {
    return seats;
  }).catch((e) => {
    setErrorMsg("Seats can not be loaded: " + e.message);
  });

  // On Page Load
  useEffect(() => {
    easyContext?.subscriptions?.map(async sub => {
      if (sub.state === 3 && sub.offer_id.toLowerCase().includes('easydirectory')) {
        const loadedSeats = await getSeats(sub.subscription_id) as Seat[];
        setSeats([...seats, ...loadedSeats]);

      }
    });
    setIsPageConfigLoading(true);
    getConfiguration();
  }, []);

  useEffect(() => {
    if (configuration && pristineOrgTags && pristineOrgViews && pristineSettings) {
      setEnableSaveTags(!arraysAreEqual(pristineOrgTags, configuration.orgTags));
      setEnableSaveViews(!arraysAreEqual(pristineOrgViews, configuration.orgViews));
      setEnableSaveSettings(!arraysAreEqual(pristineSettings.sharedMailboxes, configuration.sharedMailboxes)
        || pristineSettings.enableEntraIdSearch !== configuration.enableEntraIdSearch
        || pristineSettings.enablePersonalMailboxSearch !== configuration.enablePersonalMailboxSearch
        || pristineSettings.enableSharedMailboxSearch !== configuration.enableSharedMailboxSearch
      );
    }
  }, [configuration, pristineOrgTags, pristineOrgViews, pristineSettings]);

  const successfulToast = (
    <CToast autohide={true} visible={true} color="success" role="img" className="text-white align-items-center" onClose={() => { setSuccessMsg("") }}>
      <div className="d-flex">
        <CToastBody>{successMsg}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )

  const errorToast = (
    <CToast autohide={true} visible={true} color="danger" role="img" className="text-white align-items-center" onClose={() => { setErrorMsg("Reset") }}>
      <div className="d-flex">
        <CToastBody>{errorMsg}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )

  function updateConfiguration(updates: Partial<EasyDirectoryConfiguration>): void {
    setConfiguration({ ...configuration, ...updates });
  }

  interface EmailValidationProps {
    configuration: EasyDirectoryConfiguration;
    setConfiguration: (args: EasyDirectoryConfiguration) => void;
    setEnableSaveSettings: (args: boolean) => void;
    easyDirectorySource: SharedMailbox[];
    setEasyDirectorySource;
  }


  const RenderEmailValidation = (props: EmailValidationProps) => {
    const { configuration, setConfiguration, easyDirectorySource, setEasyDirectorySource, setEnableSaveSettings } = props;
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [hasEmailTwice, setHasEmailTwice] = useState(false);
    const [errorFields, setErrorFields] = useState<number[]>([]);

    useEffect(() => {
      validateEmails();
    }, []);

    const saveEDSource = (source, index) => {
      setEasyDirectorySource((prevEDSource) => {
        const newEDSource = [...prevEDSource];
        newEDSource[index].address = source;
        newEDSource[index].label = source;
        return newEDSource;
      });
    };

    const validateEmails = () => {
      if (easyDirectorySource[easyDirectorySource?.length - 1]?.address !== "") {
        const newMailbox: SharedMailbox = { address: "", id: "", label: "" };
        setEasyDirectorySource(easyDirectorySource.concat(newMailbox));
      }
      easyDirectorySource?.map(((email, index) => {
        validateEmail(email.address, index);
      }));
    }

    const checkEmailAlreadyExists = (email: string) => {
      return easyDirectorySource.some(c => c.address === email) ?? false;
    }

    const checkEmailExistsInGraph = (email: string) => {
      return graphService.getUser(email).then(() => {
        return true;
      }).catch(() => {
        return false;
      })
    }

    const validateEmail = async (email, index) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailExists = await checkEmailExistsInGraph(email);

      if ((index !== (easyDirectorySource?.length - 1) && email === "" && emailExists)) {
        const newSource = [...easyDirectorySource];
        newSource.splice(index, 1);

        setEasyDirectorySource(newSource);

        errorFields.indexOf(index) > -1 && errorFields.splice(errorFields.indexOf(index), 1);

        const config = { ...configuration, sharedMailboxes: newSource.slice(0, -1) };

        setConfiguration(config);
        setEmailInvalid(false);
        setHasEmailTwice(false);
      } else if ((!regex.test(email) || (new Set(easyDirectorySource)).size !== easyDirectorySource?.length || !emailExists)) {
        if (email !== "") {
          if ((new Set(easyDirectorySource)).size !== easyDirectorySource?.length) {
            setHasEmailTwice(true);
          } else {
            setEmailInvalid(true);
          }
          setErrorFields([...errorFields, index]);
          setEnableSaveSettings(false);
        }
      }
    };

    const handleSharedMailbox = (e, index) => {
      const sharedMailbox = (e.target as HTMLInputElement).value;

      const emailExists = checkEmailAlreadyExists(sharedMailbox);

      if (!emailExists && easyDirectorySource.length < 6) {
        saveEDSource(sharedMailbox, index)
        const newSource = [...easyDirectorySource];

        newSource[index]!.address = sharedMailbox;
        newSource[index]!.label = sharedMailbox;

        const config = { ...configuration, sharedMailboxes: newSource };

        if (config?.sharedMailboxes[config?.sharedMailboxes?.length - 1]?.address === "") {
          config.sharedMailboxes.splice(-1);
        }

        setConfiguration(config);
      }
      else {
        setErrorFields([...errorFields, index]);
      }
    }

    return (
      <div>
        {(emailInvalid) && <CAlert color="warning">
          {t("Onboarding.ErrorEmail")}
        </CAlert>}
        {(hasEmailTwice) && <CAlert color="warning">
          {t("Onboarding.ErrorEmailTwice")}
        </CAlert>}
        {easyDirectorySource?.map((source, index) => (
          <CRow key={index}>
            <CCol xs={11}>
              <CFormInput
                style={{ border: errorFields.includes(index) ? "2px solid red" : "" }}
                type="text"
                defaultValue={source?.address}
                size="sm"
                floatingLabel={t("SharedMailboxAddress")}
                disabled={!configuration?.enableSharedMailboxSearch}
                onKeyUp={(e) => e.key === 'Enter' && handleSharedMailbox(e, index)}
                onBlur={(e) => handleSharedMailbox(e, index)}
              />
            </CCol>
            <CCol xs={1}>
              {configuration?.enableSharedMailboxSearch && <CIcon
                onClick={() => {
                  if (easyDirectorySource?.length > 1) {
                    const newSource = [...easyDirectorySource];
                    newSource.splice(index, 1);
                    setEasyDirectorySource(newSource);
                    const config = { ...configuration, sharedMailboxes: newSource.slice(0, -1) };
                    setConfiguration(config);
                  }
                }}
                icon={cilTrash}
                size="xxl"
                style={{ 'color': 'red', marginTop: "12px", cursor: 'pointer', display: easyDirectorySource?.length > 1 && index !== easyDirectorySource?.length - 1 ? 'inline' : 'none' }}
              />}
            </CCol>
          </CRow>
        ))}

      </div>
    );
  };

  const RenderFeatureManager = (props: { configuration, setConfiguration, easyDirectorySource, setEasyDirectorySource }) => {
    const { configuration, setConfiguration, easyDirectorySource, setEasyDirectorySource } = props;
    return (
      <CCard className="p-8">
        <CCardBody>
          <CCardTitle>{t("EasyDirectory.FeaturesTitle")}</CCardTitle>
          <CRow className="mb-3">
            <CCol sm={8}>
              <CCardText>
                {t("EasyDirectory.FeaturesDescription")}
              </CCardText>
              <CFormSwitch label={t("EasyDirectory.PersonalOutlookContacts")} id="enablePersonalMailboxSearch" defaultChecked={configuration?.enablePersonalMailboxSearch} onClick={(e) => updateConfiguration({ enablePersonalMailboxSearch: (e.target as HTMLInputElement).checked } as Partial<EasyDirectoryConfiguration>)} />
              <CFormSwitch label={t("EasyDirectory.EntraIdContacts")} id="enableEntraIdSearch" defaultChecked={configuration?.enableEntraIdSearch} onClick={(e) => updateConfiguration({ enableEntraIdSearch: (e.target as HTMLInputElement).checked } as Partial<EasyDirectoryConfiguration>)} />
              <CFormSwitch label={t("EasyDirectory.ThirdPartyContacts")} id="enableSharedMailboxSearch" defaultChecked={configuration?.enableSharedMailboxSearch} onClick={(e) => updateConfiguration({ enableSharedMailboxSearch: (e.target as HTMLInputElement).checked } as Partial<EasyDirectoryConfiguration>)} />
              <br />
              <RenderEmailValidation
                configuration={configuration}
                setConfiguration={setConfiguration}
                easyDirectorySource={easyDirectorySource}
                setEasyDirectorySource={setEasyDirectorySource}
                setEnableSaveSettings={setEnableSaveSettings}
              />
            </CCol>
            <CCol sm={4}>
            </CCol>
          </CRow>
          <CRow className="tcf-flexbox tcf-align-end">
            <CCol xs={2} className="tcf-flexbox tcf-align-end">
              <CButton disabled={!enableSaveSettings} className="mr-1 mb-1" style={{
                backgroundColor: !enableSaveSettings && "Gray",
                borderColor: !enableSaveSettings && "Gray"
              }} color="primary" onClick={() => openModal(saveSettings)}>
                {t("Button.SaveSettings")}
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };

  const RenderTagsManager = (props: { configuration, setConfiguration }) => {
    const { configuration } = props;

    const handleAvailableTag = (tag: EasyTag, mode: string) => {
      setConfiguration(prevConfig => {
        const newConfig = { ...prevConfig };
        if (mode === "add" && !newConfig.orgTags?.some(t => t.label === tag.label)) {
          newConfig.orgTags = [...(newConfig.orgTags || []), tag];
        } else if (mode === "delete") {
          newConfig.orgTags = newConfig.orgTags?.filter(removaltag => removaltag?.id !== tag?.id);
        }
        return newConfig;
      });
    };
    return (
      <CCard>
        <CCardBody>
          <CCardTitle>{t("EasyDirectory.ManageTags")}</CCardTitle>
          <CRow>
            <CCol xs={12}>
              <CFormInput
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAvailableTag({ id: uuid(), label: (e.target as HTMLInputElement).value }, "add");
                    (e.target as HTMLInputElement).value = "";
                  }
                }}
                text={t("AvailableTagsDescription")}
              />
              <div className="tcf-available-tags">
                {configuration?.orgTags?.map((tag) => {
                  return <><CButton key={tag?.id} color="light" size="sm" onClick={() => handleAvailableTag(tag, "delete")}>{tag?.label} <CIcon icon={cilX} /> </CButton>&nbsp;</>
                })}
              </div>
            </CCol>
          </CRow>
          <CRow className="tcf-flexbox tcf-align-end">
            <CCol xs={2} className="tcf-flexbox tcf-align-end">
              <CButton disabled={!enableSaveTags} className="mr-1 mb-1" style={{
                backgroundColor: !enableSaveTags && "Gray",
                borderColor: !enableSaveTags && "Gray"
              }} color="primary" onClick={() => openModal(saveTags)}>
                {t("Button.SaveTags")}
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard >
    );
  };

  const RenderViewManager = (props: { configuration, selectedView, setSelectedView, setConfiguration }) => {
    const { configuration, selectedView, setSelectedView, setConfiguration } = props;

    const findViewById = (data, viewId) => data.flatMap(user => user.view)!.find(view => view?.id === viewId);

    const handleAvailableView = async (view: View, mode: string) => {
      setConfiguration(prevConfig => {
        const newConfig = { ...prevConfig };
        if (mode === "add") {
          if (newConfig.orgViews?.some(item => item?.id === view?.id)) {
            alert(t("EasyDirectory.ViewAlreadyAdded"));
            return prevConfig;
          }
          newConfig.orgViews = [...(newConfig.orgViews || []), view];

          // Remove the added view from viewSelectionList
          setViewSelectionList(prevList =>
            prevList.map(group => ({
              ...group,
              options: group.options.filter(option => option.value !== view.id)
            })).filter(group => group.options.length > 0)
          );
        } else if (mode === "delete") {
          newConfig.orgViews = newConfig.orgViews?.filter(removalView => removalView?.id !== view?.id);

          // Add the removed view back to viewSelectionList
          const userView = customViews?.find(userView => userView.view.some(v => v.id === view.id));
          if (userView) {
            graphService.getUser(userView.userId).then(person => {
              setViewSelectionList(prevList => {
                const existingGroup = prevList.find(group => group.label === person.displayName);
                if (existingGroup) {
                  return prevList.map(group =>
                    group.label === person.displayName
                      ? { ...group, options: [...group.options, { value: view.id, label: view.label }] }
                      : group
                  );
                } else {
                  return [...prevList, {
                    label: person.displayName,
                    options: [{ value: view.id, label: view.label }]
                  }];
                }
              });
            });
          }
        }
        return newConfig;
      });
    };

    const OnSelectedView = (newValue, actionMeta) => {
      switch (actionMeta.action) {
        case 'select-option':
          setSelectedView(findViewById(customViews, newValue.value));
          break;
        case 'remove-value':
        case 'pop-value':
          setSelectedView(undefined);
          break;
      }
    };

    const userOptions = useMemo(() =>
      viewSelectionList.map(group => ({
        value: group.label,
        label: group.label
      })),
      [viewSelectionList]
    );

    const renderJSON = (jsonString: string) => {
      try {
        const parsedJSON = JSON.parse(jsonString);
        return (
          <pre style={{
            backgroundColor: '#f4f4f4',
            padding: '10px',
            borderRadius: '5px',
            overflow: 'auto',
            maxHeight: '200px'
          }}>
            {JSON.stringify(parsedJSON, null, 2)}
          </pre>
        );
      } catch (error) {
        return <span>Invalid JSON</span>;
      }
    };

    const renderPageOptions = (pageOptions) => {
      if (!pageOptions) return null;
      return (
        <div>
          {pageOptions.presenceView !== undefined && (
            <p><strong>Presence View:</strong> {pageOptions.presenceView ? "✅" : "❌"}</p>
          )}
          {pageOptions.fullScreen !== undefined && (
            <p><strong>Full Screen:</strong> {pageOptions.fullScreen ? "✅" : "❌"}</p>
          )}
          {pageOptions.enableTagging !== undefined && (
            <p><strong>Enable Tagging:</strong> {pageOptions.enableTagging ? "✅" : "❌"}</p>
          )}
          {pageOptions.groupedBy && (
            <div>
              <strong>Grouped By:</strong>
              {renderJSON(JSON.stringify(pageOptions.groupedBy))}
            </div>
          )}
        </div>
      );
    };

    const handleUserChange = (selectedOption) => {
      setSelectedUser(selectedOption);
      setSelectedView(null);
    };

    const filteredViewSelectionList = selectedUser
      ? viewSelectionList.filter(group => group.label === selectedUser.label)
      : viewSelectionList;

    return (
      <CCard>
        <CCardBody>
          <CCardTitle>{t("EasyDirectory.ManageOrganizationViews")}</CCardTitle>
          <CRow className="mb-3">
            {/* Left Column for Select and Badges */}
            <CCol xs={6} className="d-flex flex-column">
              <Select
                options={userOptions}
                onChange={handleUserChange}
                value={selectedUser}
                isClearable
                placeholder={t("Select.FilterByUser")}
                className="mb-3  me-2"
              />
              <div className="d-flex align-items-center mb-3">
                <Select
                  options={filteredViewSelectionList}
                  onChange={OnSelectedView}
                  className="tcf-custom-view-select me-2"
                  isSearchable
                  isClearable
                />
                <CButton disabled={selectedView === null} color="dark" onClick={() => handleAvailableView(selectedView, "add")}>{t("Add")}</CButton>
              </div>
              <CCard className="mt-3">
                <CCardBody>
                  {selectedView !== null ? <>
                    <CCardTitle>{selectedView?.label}</CCardTitle>
                    <div>
                      {selectedView?.id && <p><strong>ID:</strong> {selectedView?.id}</p>}
                      {selectedView?.searchTemplate && (
                        <div>
                          <strong>Search Template:</strong>
                          {renderJSON(selectedView.searchTemplate)}
                        </div>
                      )}
                      {renderPageOptions(selectedView?.pageOptions)}
                    </div>
                  </> : <CCardTitle style={{ textAlign: "center", color: "gray" }}><span>{t("Preview")}</span></CCardTitle>}
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs={6}>
              <div className="tcf-org-views">
                {configuration?.orgViews?.map((views) => (
                  <CButton style={{ marginRight: "5px" }} className="mr-1 mb-1" key={views?.id} color="light" size="sm" onClick={() => handleAvailableView(views, "delete")}>
                    {views?.label} <CIcon icon={cilX} />
                  </CButton>
                ))}
              </div>
            </CCol>
          </CRow>

          <CRow className="tcf-flexbox tcf-align-end">
            <CCol xs={2} className="tcf-flexbox tcf-align-end">
              <CButton disabled={!enableSaveViews} style={{
                backgroundColor: !enableSaveViews && "Gray",
                borderColor: !enableSaveViews && "Gray"
              }} color="primary" onClick={() => openModal(saveView)}>
                {t("Button.SaveViews")}
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    );
  };

  const RenderSaveChangesModal = () => {
    return (<ConfirmationModal visible={modalVisible}
      toggleModal={toggleModal}
      onConfirm={onConfirm} />);
  };

  return (isAuthenticated && easyContext.productLicenses?.EasyDirectory?.isLicensed ? (
    <div>
      <br />
      {isPageConfigLoading ?
        <div className="d-flex justify-content-center tcf-loading-background">
          <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
        </div> : <></>
      }

      <CToaster className="p-3" placement="top-end" push={toast} ref={toaster} />
      <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory Configuration" width={370} /><br /><br />
      <p>
        {t("EasyDirectory.Introduction")}
      </p>
      <CRow>
        <CCol xs={6} >
          <CCard className="tcf-panel-card">
            <CCardBody>
              <CRow>
                <CCol>
                  <ReactPlayer url='https://www.youtube.com/watch?v=mcSgNQXaWQw' pip={true} playsinline={true} controls={false} width='100%' />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard className="tcf-panel-card">
            <CCardImage orientation="top" src={LicenseImg} className="tcf-card-img" />
            <CCardBody>
              {/* licenseCount needs to be replaced by an actual data */}
              <CCardTitle>{seats?.length + " / " + easyContext.productLicenses?.EasyDirectory?.seatCount} {t("EasyDirectory.LicensesTitle")}</CCardTitle>
              <CCardText>
                {t("EasyDirectory.LicensesDescription")}<br /><br />

              </CCardText>
              <CButton
                className="mr-1 mb-1"
                color="dark"
                href="/subscriptions"
              >
                <CIcon icon={cilGroup} />
                &nbsp;&nbsp;{t("Button.ReserveSeats")}
              </CButton>
              <CButton className="mr-1 mb-1" href="/subscriptions" color="dark">
                <CIcon icon={cilLoopCircular} />
                &nbsp;&nbsp;{t("EasyDirectory.LicensesButton")}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard className="tcf-panel-card">
            <CCardImage orientation="top" src={SupportImg} className="tcf-card-img" />
            <CCardBody>
              <CCardTitle>{t("SupportTitle")}</CCardTitle>
              <CCardText>
                {t("SupportDescription")}<br /><br />
                <CLink href="https://docs.easydirectory.easyplatform.app/" target="_blank">Easy Directory Configuration Guide</CLink><br /><br />
              </CCardText>
              <CButton href="mailto:support@twincapfirst.ch?subject=Easy%20Call%20Report" target="_blank" color="dark">
                <CIcon icon={cilPhone} />
                &nbsp;&nbsp;{t("SupportButton")}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <br />
      <RenderFeatureManager
        configuration={configuration}
        setConfiguration={setConfiguration}
        easyDirectorySource={easyDirectorySource}
        setEasyDirectorySource={setEasyDirectorySource} />
      <br />
      <RenderTagsManager configuration={configuration} setConfiguration={setConfiguration} />
      <br />
      <RenderRoleManager
        configuration={configuration}
        setConfiguration={setConfiguration}
        setSuccessMsg={setSuccessMsg}
        addToast={addToast}
        setErrorMsg={setErrorMsg}
        errorToast={errorToast}
        successfulToast={successfulToast} />
      <br />
      <RenderViewManager configuration={configuration} selectedView={selectedView} setConfiguration={setConfiguration} setSelectedView={setSelectedView} />
      <br />
      <RenderSaveChangesModal />
    </div>) : (
    <div className="d-flex justify-content-center tcf-loading-background">
      <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
    </div>)
  )
}

export default EasyDirectory